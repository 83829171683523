<template>

  <div class="service_form_details">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2 ncf_details_header"
        cols="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="6">
            <h1 class="custom-header-title">
              {{ ncf.stringID }}
            </h1>
            <p class="log_info">
              Created by {{ ncf.createdBy ? ncf.createdBy.name : '' }} on {{ dateFormatWithTime(ncf.createdAt) }}<br>Last updated on {{ dateFormatWithTime(ncf.updatedAt) }}
            </p>
          </b-col>
          <b-col
            v-if="ncf.signatories"
            class="text-right justify-content-end align-items-right ml-auto"
            cols="6"
          >
            <div class="demo-inline-spacing">
              <div
                v-for="(signatory, index) of ncf.signatories"
                :key="index"
              >
                <b-button
                  v-if="signatory.signed === false"
                  v-b-tooltip.hover
                  :title="`Pending Sign by ${signatory.user ? signatory.user.name : ''}`"
                  variant="flat-primary"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="FeatherIcon"
                    size="18"
                  />
                </b-button>
                <b-button
                  v-else
                  v-b-tooltip.hover
                  :title="`Signed by ${signatory.user ? signatory.user.name : ''}`"
                  variant="success"
                  class="btn-icon"
                >
                  <feather-icon
                    icon="CheckIcon"
                    size="18"
                  />
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details ncf_details serviceForm_details"
    >
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="9"
        >
          <h3>Non-Conformance Details</h3>
        </b-col>
      </b-row>
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Action Taken By
            </b-th>
            <b-td>{{ ncf.createdBy ? ncf.createdBy.name : '' }}</b-td>
            <b-th width="230px">
              Created By
            </b-th>
            <b-td>{{ ncf.createdBy ? ncf.createdBy.name : '' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Type of Non-Conformance
            </b-th>
            <b-td>{{ ncf.type }}</b-td>
            <b-th width="230px">
              Date
            </b-th>
            <b-td>{{ dateFormat(ncf.createdAt) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Material
            </b-th>
            <b-td>{{ ncf.itemName }}</b-td>
            <b-th width="230px">
              Quantity
            </b-th>
            <b-td>{{ ncf.quantity ? ncf.quantity : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Vendor
            </b-th>
            <b-td>{{ ncf.vendor ? ncf.vendor.companyName : '' }}</b-td>
            <b-th width="230px">
              Nature of Defect
            </b-th>
            <b-td>{{ ncf.natureOfDefect ? ncf.natureOfDefect : '-' }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Related PR / PO
            </b-th>
            <b-td>
              <div v-if="canViewThisAction('show', 'PurchaseRequest')">
                <b-button
                  variant="link"
                  class="p-0"
                  :to="{ name: 'purchasing-purchase-request-show', params: { id: ncf.purchaseRequest ? ncf.purchaseRequest._id : 0 } }"
                >
                  {{ ncf.purchaseRequest ? ncf.purchaseRequest.caseID : '' }}
                </b-button>
              </div>
              <div v-else>
                <span>{{ ncf.purchaseRequest ? ncf.purchaseRequest.caseID : '' }}</span>
              </div>
            </b-td>
            <b-th width="230px">
              Status
            </b-th>
            <b-td class="text-capitalize">
              {{ ncf.status ? ncf.status : '-' }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
    <b-card
      class="mb-3 p-2 without__bg"
      no-body
    >
      <div class="pur_con_header">
        <b-row
          class="content-header"
        >
          <!-- Content Left -->
          <b-col
            class="content-header-left mb-2"
            cols="12"
            md="6"
          >
            <b-button
              variant="primary"
              class="mr-1 md-mb-1"
              @click="$refs.hiddenFileInput.click()"
            >
              Upload File
            </b-button>
            <b-button
              v-if="attachments.length"
              variant="success"
              class="mr-1 md-mb-1"
              @click="uploadFile"
            >
              Save File
            </b-button>
            <input
              ref="hiddenFileInput"
              type="file"
              multiple
              capture
              accept="image/jpeg, image/png, application/pdf"
              class="d-none"
              @change="setAttachment"
            >
            <b-button
              variant="primary"
              class="mr-1 md-mb-1"
              :disabled="ncf.status == 'cancelled' || ncf.status == 'closed' || !(canViewThisAction('update', 'NonConformanceForm') || (canViewThisAction('my-department-ncf-update', 'NonConformanceForm') && departmentPermission))"
              @click="warningBeforeEdit()"
            >
              Edit
            </b-button>
            <b-button
              variant="primary"
              class="md-mb-1"
              :disabled="ncf.status != 'closed' || !canViewThisAction('download-ncf', 'NonConformanceForm')"
              @click="downloadNCF"
            >
              <feather-icon
                icon="DownloadIcon"
                size="14"
              />
            </b-button>
          </b-col>
          <b-col
            class="content-header-left mb-2 flex"
            cols="12"
            md="6"
            style="display: inline-flex; flex-direction: row-reverse;"
          >
            <b-button
              v-if="user._id == (ncf.createdBy ? ncf.createdBy._id : '') && ncf.status == 'draft'"
              variant="success"
              :to="{ name: 'purchasing-ncf-preview', params: { id: $route.params.id } }"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
                size="18"
              />
              <span class="align-middle">
                Submit For Approval
              </span>
            </b-button>
            <b-button
              v-if="ncf.status != 'cancelled' && ncf.status != 'closed' && (canViewThisAction('update', 'NonConformanceForm') || user._id == (ncf.createdBy ? ncf.createdBy._id : ''))"
              variant="warning"
              class="mx-1"
              @click="showCancelWarning(ncf._id, ncf.stringID)"
            >
              <span class="align-middle">
                Cancel NCF
              </span>
            </b-button>
            <div
              v-for="(signatory, index) of ncf.signatories"
              :key="index"
            >
              <b-button
                v-if="ncf.status != 'cancelled' && index != 0 && user._id == (signatory.user ? signatory.user._id : '') && ncf.signatories[index - 1].signed == true && signatory.signed == false"
                variant="success"
                :to="{ name: 'purchasing-ncf-preview', params: { id: $route.params.id }, query: { type: index, user: signatory._id } }"
              >
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                  size="18"
                />
                <span class="align-middle">
                  Approve NCF
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="pur_con_body quote__table">
        <div class="ncf_details_info">
          <h6 class="mb-2">
            Proposed Solution
          </h6>
          <p class="text-bold">
            Proposed Action
          </p>
          <span>{{ ncf.proposedSolution }}</span>
          <b-row class="mt-2">
            <b-col
              cols="12"
              md="6"
            >
              <p class="text-bold">
                Remark
              </p>
              <span>{{ ncf.remarks ? ncf.remarks : '-' }}</span>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <p class="text-bold">
                Corrective Action By External Provider
              </p>
              <span>{{ ncf.correctiveAction ? ncf.correctiveAction : '-' }}</span>
            </b-col>
          </b-row>
        </div>

        <hr
          v-if="ncf.attachments && ncf.attachments.length"
          class="dividerHR"
        >
        <div
          v-if="ncf.attachments && ncf.attachments.length"
          class="attach__block"
        >
          <b-row
            class="content-header"
          >

            <!-- Content Left -->
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="9"
            >
              <h3>Attachments</h3>
            </b-col>
          </b-row>
          <b-row>
            <!-- Content Left -->
            <b-col
              v-for="(file, key) in ncf.attachments"
              :key="key"
              class="content-header-left mb-2"
              cols="12"
              md="4"
            >
              <div
                class="attachment-item"
              >
                <b-img
                  v-if="file.type.includes('image')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_jpg.png')"
                  alt="Left image"
                />
                <b-img
                  v-if="file.type.includes('application')"
                  left
                  height="40"
                  :src="require('@/assets/images/admin/doc_file.png')"
                  alt="Left image"
                />
                <strong>
                  <b-link
                    :href="file ? file.data : ''"
                    target="_blank"
                  >
                    {{ file ? file.name : '' }}
                  </b-link>
                </strong>
                <span>{{ file ? `${file.size}mb` : '' }}</span>
                <span class="remove__doc">
                  <b-button
                    variant="flat-primary"
                    @click="removeAttachment(file.data)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-50"
                      size="18"
                    />
                  </b-button>
                </span>
              </div>
              <div class="doc__desc">
                <span v-if="file.description">
                  {{ file.description }}
                </span>
                <span v-else>
                  <button @click="updateDescription(key)">Add a description</button>
                </span>
                <span
                  v-if="file.description"
                  class="edit__desc"
                >
                  <b-button
                    variant="flat-primary"
                    @click="updateDescription(key)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      class="mr-50"
                      size="18"
                    />
                  </b-button>
                </span>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="signatories_block mt-2">
        <b-row
          class="content-header"
        >

          <!-- Content Left -->
          <b-col
            v-for="(signatory, index) of ncf.signatories"
            :key="index"
            cols="3"
            md="3"
            class="mt-md-0 mt-3"
            order="2"
            order-md="1"
          >
            <b-card-text class="mb-0">
              <span class="font-weight-bold">{{ index + 1 }}. {{ signatory.type }}</span>
              <div class="sign-box">
                <div v-if="signatory.user">
                  <b-avatar
                    v-if="signatory.user.image"
                    :src="signatory.user.image"
                  />
                  <b-avatar
                    v-else
                    :text="resolveAcronymName(signatory.user.name)"
                  />
                </div>
                <div v-if="signatory.signed == true">
                  <!-- <div v-if="signatory.user && signatory.user.signature">
                    <b-img
                      class="mb-1 mb-sm-0 user__avatar"
                      height="50"
                      :src="signatory.user.signature"
                    />
                  </div> -->
                  <div v-if="signatory.user && signatory.user.name">
                    <span>{{ signatory.user.name }}</span>
                  </div>
                </div>
                <div v-else>
                  <p>Pending Signature</p>
                </div>
              </div>
              <p class="ml-75 semi-bold">
                {{ signatory.user ? signatory.user.name : '' }}
              </p>
              <p class="ml-75">
                {{ signatory.user ? (signatory.user.department.length ? signatory.user.department[0].name : '') : '' }}
              </p>
              <p class="ml-75">
                {{ signatory.signed == true ? dateFormatWithTime(signatory.date) : '-' }}
              </p>
            </b-card-text>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- <pdf-view
      ref="pdfGenerateComponentPO"
      :ncf="ncf"
      :pdf-total-page="pdfTotalPage"
      :gap-height="gapHeight"
    /> -->

    <b-modal
      id="modal-attachment-description"
      size="lg"
      title="Attachment Description"
      modal-class="status-update-modal"
      centered
      no-stacking
      header-class="front-modal"
      @ok="saveDescription"
      @cancel="closeDescriptionModal"
    >
      <b-row>
        <b-col md="12">
          <b-form-textarea
            id="h-customer-phone-number"
            v-model="description"
            placeholder="E.g. Please remind to make payment"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          :disabled="!description || attachmentKey === ''"
          @click="ok()"
        >
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BAvatar, VBTooltip, BImg, BLink, BCardText, BFormTextarea,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import store from '@/store/index'
import { jsPDF } from 'jspdf'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '../../../assets/js/DroidSans-Regular-normal'
import '../../../assets/js/DroidSans-Bold-normal'
// import '../../../assets/js/Montserrat-Bold-normal'
// import PdfView from './PDFView.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BAvatar,
    BImg,
    BLink,
    BCardText,
    BFormTextarea,

    // PdfView,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      canViewThisAction,
      user: store.state.auth.userData,
      ncf: {},
      description: '',
      attachmentKey: '',
      attachments: [],
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      departmentPermission: false,
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (to.query.from && to.query.from === 'my-ncf') {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-my-ncf-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'My Non-Conformance Forms',
          to: '/purchasing/my-ncf/all-ncfs',
        },
        {
          text: 'Non-Conformance Form Detail',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    } else {
      // eslint-disable-next-line no-param-reassign
      to.meta.navActiveLink = 'purchasing-ncf-index'
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      await store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const breadcrumb = [
        {
          text: 'Non-Conformance Forms',
          to: '/purchasing/ncf/all-ncfs',
        },
        {
          text: 'Non-Conformance Form Detail',
          active: true,
        },
      ]
      store.commit('breadcrumb/ADD_BREADCRUMB', breadcrumb)
    }
    next()
  },
  mounted() {
    this.$http.get(`purchase/ncf/${this.$route.params.id}/show`)
      .then(response => {
        this.ncf = response.data.data || {}
        const userDepartmentArray = this.user.department.map(({ _id }) => _id)
        const purchaserDepartmentArray = this.ncf.purchaseRequest.createdBy.department.filter(d => userDepartmentArray.includes(d))
        if (purchaserDepartmentArray.length) {
          this.departmentPermission = true
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    resolveDepartmentPermission() {
      const userDepartmentArray = this.user.department.map(({ _id }) => _id)
      const purchaserDepartmentArray = this.ncf.purchaseRequest.createdBy.department.filter(d => userDepartmentArray.includes(d))
      if (purchaserDepartmentArray.length) {
        return true
      }
      return false
    },
    setAttachment(e) {
      if (e.target.files.length) {
        // const reader = new FileReader()
        // reader.readAsDataURL(this.file)
        // reader.onload = () => {
        //   e.target.filesrc = reader.result
        // }
        const acceptedTypes = ['image/jpeg', 'image/png', 'application/pdf']
        e.target.files.forEach(element => {
          if (acceptedTypes.includes(element.type)) {
            if (element.size > 5 * 1024 * 1024) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Can't add files more than 5mb",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            } else {
              const reader = new FileReader()
              reader.readAsDataURL(element)

              reader.onload = event => {
                const fileObj = {}
                fileObj.name = element.name
                fileObj.data = event.target.result
                fileObj.size = (element.size / (1024 * 1024)).toFixed(2)
                fileObj.type = element.type
                this.attachments.push(fileObj)
              }
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unsupported file type',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
      }
    },
    uploadFile() {
      const formData = new FormData()
      formData.append('attachments', JSON.stringify(this.attachments))
      formData.append('ncf', this.$route.params.id)
      this.$http.post('purchase/ncf/upload/new-file', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          this.attachments = []
          this.ncf = response.data.data || {}
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    closeDescriptionModal() {
      this.$root.$emit('bv::hide::modal', 'modal-attachment-description', '')
    },
    resolveAcronymName(name) {
      return name.split(' ').map(item => item.charAt(0)).join('').toUpperCase()
    },
    updateDescription(key) {
      this.attachmentKey = key
      this.description = this.ncf.attachments[key].description
      this.$root.$emit('bv::show::modal', 'modal-attachment-description', '')
    },
    removeAttachment(url) {
      this.$http
        .post('purchase/ncf/remove-attachment', { url, ncf: this.$route.params.id })
        .then(response => {
          this.ncf.attachments = response.data.data.attachments
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    warningBeforeEdit() {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Updating or editing the NCF will require the department staff and HOD to re-sign on the form.<br><br>Proceed only if the changes are necessary to the request.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, Cancel',
        confirmButtonText: 'Yes, I am Sure',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-ncf-edit', params: { id: this.$route.params.id } })
          }
        })
    },
    showCancelWarning(id, caseID) {
      this.$swal({
        title: 'Are You Sure?',
        html: 'Cancelling this NCF will not allow you to edit this anymore. You will have to create a new NCF.<br><br><span class="text-bold-black">This action cannot be undone.</span>',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, cancel this NCF',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$http.post(`purchase/ncf/${id}/cancel`, {})
              .then(response => {
                this.ncf = response.data.data || {}
                this.$swal({
                  title: 'NCF Cancelled!',
                  html: `NCF <strong>${caseID}</strong> has been cancelled`,
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/success.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: false,
                  confirmButtonText: 'Okay',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(error => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    downloadNCF() {
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/purchase-request/download/ncf/${this.$route.params.id}`)
      formData.append('landscape', true)
      this.$http.post('purchase/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `${this.ncf.stringID}.pdf`
          link.click()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async oldDownloadNCF() {
      await this.calculateTotalPage()
      const vm = this
      const element = document.getElementById('pdfGenerateDiv')
      // element.style.fontFamily = 'Montserrat-Regular'
      element.style.letterSpacing = '0.5px'
      let height = 282.5
      if (this.pdfTotalPage === 1) {
        height = 297
      }
      if (this.pdfTotalPage === 2) {
        height = 284
      }
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', [height, 210], true)
      // const pageWidth = pdf.internal.pageSize.width - (10 * 2)
      // pdf.setFont('Montserrat-Regular')
      pdf.setProperties({
        title: `${vm.ncf.stringID}`,
      })
      pdf.html(element, {
        margin: [5, 5, 5, 5],
        html2canvas: {
          scale: 0.25,
        },
        // width: pageWidth,
        // windowWidth: pageWidth,
        callback(pdfr) {
          pdfr.save(`${vm.ncf.stringID}.pdf`)
        },
        // x: 10,
        // y: 10,
        autoPaging: 'text',
        // fontFaces: [{
        //   family: 'Montserrat-Regular', weight: 400, stretch: 'normal', src: ['assets/fonts/Montserrat-Regular.ttf'],
        // }],
      })
    },
    async calculateTotalPage() {
      await this.$nextTick()
      this.pdfTotalPage = 0
      const logoHeight = document.getElementById('main-header').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      const dHeight = 1080
      this.pdfTotalPage = 1
      const finalDataHeight = document.getElementById('content-div').offsetHeight
      this.gapHeight = dHeight - (logoHeight + footerHeight + finalDataHeight)
      if (finalDataHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage = 2
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    saveDescription() {
      const url = this.ncf.attachments[this.attachmentKey].data
      this.$http
        .post('purchase/ncf/save-description', { url, description: this.description, ncf: this.$route.params.id })
        .then(response => {
          this.ncf.attachments = response.data.data.attachments
          this.closeDescriptionModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || '',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style scoped>
  .timeline-variant-info .timeline-item-point {
    background-color: #00cfe8 !important;
  }
</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
